import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Spring } from 'react-spring/renderprops';
import {Helmet} from "react-helmet";
import styled from 'styled-components';


const Button = styled.div`
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0px;
    /* margin-left: 20px; */
    /* margin: auto; */
    /* float: right; */
`

const Line = styled.div`
    background: black;
    width: 30px;
    height: 1px;
    border: 1px solid black;
    margin: 8px 0px 8px 0px;

`


function Toggler(props){
    return(

        <Button onClick={props.closed}>
            <Line/>
            <Line/>
            <Line/>
        </Button>
        
    )
    
}

export default Toggler;
