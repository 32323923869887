import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Spring } from 'react-spring/renderprops';
import {Helmet} from "react-helmet";
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  
} from "react-router-dom";



const Nav = styled.nav`
  padding-left:25px;
  padding-right:25px;
  box-shadow: 1px 10px 30px rgba(0, 0, 0, 0.562);
  
  
`


function Footer(props) {
  return (

    <Nav className="navbar fixed-bottom navbar-light bg-light">
    <div className="container-fluid">
    
    <p style={{ color:"#4646d4",fontSize:"10px", margin:"auto"}}>ThoughtScape LLC. ©2021</p>
    </div>
  </Nav>
  );
};

export default Footer;
