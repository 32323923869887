import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Spring } from 'react-spring/renderprops';
import {Helmet} from "react-helmet";
import styled from 'styled-components';
import email from './assets/images/email.png'

const Landing = styled.div`
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

`

function LandingPage(props){
    return(

        <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            >

        {props=>(
            <Landing>
               
                <h1 className="hvr-buzz-out" style={{fontSize:"45px"
                , textShadow:"1px 1px 1px #436aa8"}}>
                thoughtscape.io</h1>

                <p >A playground for ideas.</p>
                <br/>
                <div style={{width:'90%',
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            marginTop:"20px"}}>

                <i className="material-icons large" 
                style={{marginRight:"5px",
                        color:"#436aa8"}} >
                 send</i> 

                <h5 style={{display:"inline-block",
                           color:"#436aa8",
                           marginBottom:"0",
                           fontSize: "12pt" }}>
                           info@thoughtscape.io</h5>
                </div>

            </Landing>

        )}
       
        </Spring>
    )
    
}

export default LandingPage;
