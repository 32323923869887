import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Spring } from 'react-spring/renderprops';
import {Helmet} from "react-helmet";
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  
} from "react-router-dom";
import './assets/css/Header.css'

import bulb from './assets/images/bulb.png'
import Toggler from '../toggle-btn/Toggler';

const Nav = styled.nav`
  padding-left:25px;
  padding-right:25px;
  box-shadow: 1px 10px 30px rgba(0, 0, 0, 0.562);
  
`


function Header(props) {
  return (

    <Nav className="navbar fixed-top navbar-light bg-light">
    <div className="container-fluid">
      <a className="navbar-brand" href="#"><img className="header-icon" src={bulb} /></a>
      <p className=""></p>
      {/* <Toggler/> */}
    </div>
  </Nav>
  );
};

export default Header;
