import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Spring } from 'react-spring/renderprops';
import {Helmet} from "react-helmet";
import styled from 'styled-components';

const Landing = styled.div`
  /* background-color: brown; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

`

function About(props){
    return(

        <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            >

        {props=>(
            <Landing>
                <div className="content-component">
                    <h1 className="hvr-grow">About <br/>thoughtscape.io</h1>
                    <p className="justify">Normally, both your asses would be dead as fucking fried chicken, but you happen to pull this shit while I'm in a transitional period so I don't wanna kill you, I wanna help you. But I can't give you this case, it don't belong to me. Besides, I've already been through too much shit this morning over this case to hand it over to your dumb ass.</p>
                    <br/>
                    <p>info@thoughtscape.io</p>
                </div>
                
            </Landing>

        )}
       
        </Spring>
    )
    
}

export default About;