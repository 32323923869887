import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Spring } from 'react-spring/renderprops';
import {Helmet} from "react-helmet";
import styled from 'styled-components';


const Drawer = styled.div`
    background: rgba( 5,0,0,1);
    box-shadow: 1px -20px 20px rgba(0, 0, 0, 0.562);
    width: 70%;
    max-width: 250px;
    float: right;
    height: 100%;
    position: fixed;
    z-index: 200;
    top: 0;
    right: 0;
    padding-top: 110px;
    transform: translate(100%);
    transition: transform 500ms ease-in-out;
    align-items: center;
`





function SideDrawer(props){

    const cssClasses = [props.show ? 'drawerOpen' : 'drawerClosed' ];

    return(

        <Drawer className={cssClasses.join(' ')}>
            
        </Drawer>
        
    )
    
}

export default SideDrawer;