import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Spring } from 'react-spring/renderprops';
import {Helmet} from "react-helmet";
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  
} from "react-router-dom";
import './assets/css/App.css';
import './assets/css/hover.css';

// components
import Header from '../src/assets/components/header/Header'
import Footer from '../src/assets/components/footer/Footer'
import SideDrawer from '../src/assets/components/side-drawer/SideDrawer'

// pages
import LandingPage from '../src/assets/components/pages/landing/Landing'
import About from '../src/assets/components/pages/about/About'

function App() {

 

  return (

      
       
      <div >
        <Router>
        <Header />
        <Switch>
          <div className="container">
              <div className="row">
                <Route path="/" exact><LandingPage /></Route>
                <Route path="/about"><About /></Route>
              </div>
          </div>
        </Switch>
        </Router>
        <SideDrawer />
        <Footer />
        </div>
       
       
    


  
  );
};

export default App;
